import {
  type AnalyticsClient,
  type AnalyticsTrackDirective,
  setupAnalyticDirectives,
} from "@pollen/core/client/composables/analytics.hook";
import { type ComponentCustomProperties as _ComponentCustomProperties } from "vue";

import type { SatisfactionWizardEvent } from "~/business-areas/satisfaction/satisfaction.model";
import type { SessionActionKey } from "~/business-areas/session/composables/session-action.hook";
import type { SessionSectionKey } from "~/business-areas/session/composables/session-section.hook";
import type {
  SessionApiView,
  SessionListApiView,
} from "~~/model/session.model";

type AnalyticsSessionView = Pick<
  DatabaseTable<"course_sessions">,
  "id" | "start_date" | "end_date"
> & {
  course: Pick<DatabaseTable<"courses">, "title">;
};

type TrackingPlanModel = {
  signature_started: undefined;
  signature_completed: undefined;
  session_card_clicked: { session: AnalyticsSessionView };
  session_viewed: undefined;
  session_section_unfolded: {
    section: SessionSectionKey;
  };
  session_action_viewed: {
    section: SessionSectionKey;
    action: SessionActionKey;
  };
  session_action_clicked: {
    section: SessionSectionKey;
    action: SessionActionKey;
  };
  session_outcomes_unfolded: undefined;
  session_requirements_unfolded: undefined;
  session_teacher_unfolded: undefined;
  session_document_clicked: {
    document: Pick<DatabaseTable<"resources">, "id" | "title">;
  };
  session_certificate_clicked: undefined;
  session_course_page_clicked: undefined;
  session_trainer_page_clicked: undefined;
  session_map_clicked: undefined;
  session_attendees_viewed: undefined;
  session_catalog_banner_clicked: undefined;

  satisfaction_flow_started: undefined;
  satisfaction_flow_completed: undefined;
  satisfaction_flow_step: SatisfactionWizardEvent;
};

declare module "#app" {
  interface NuxtApp {
    $analytics: AnalyticsClient<TrackingPlanModel>;
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties extends _ComponentCustomProperties {
    vTrack: AnalyticsTrackDirective<TrackingPlanModel>;
  }
}

export function toAnalyticsSessionView(
  session: SessionApiView | SessionListApiView,
): AnalyticsSessionView {
  return {
    id: session.id,
    start_date: session.start_date,
    end_date: session.end_date,
    course: {
      title: session.course.title,
    },
  };
}

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: {
      segment: { apiKey },
    },
  } = useRuntimeConfig();

  const analytics = getAnalyticsClient<TrackingPlanModel>(apiKey);

  setupAnalyticDirectives<TrackingPlanModel>(nuxtApp);

  return {
    provide: {
      analytics,
    },
  };
});
